import { get, del, post, put } from "helpers/api_helper"

const AGENCY_URL = "/agencies"
const CART_URL = "/cart"
const DELETE_MULTIPLE_CART_URL = "/cart/delete-multiple?"
const PRODUCT_CATEGORY_URL = "/product-categories"
const PRODUCT_URL = "/products?get_all=1&include[]=images"
const NOTIFICATION_URL = "/notifications?include[]=user"
const ADMIN_GET_NOTIF_URL_PAGE = "/notifications?include[]=user"
const CURRENT_PRODUCT_URL = "/products"
const SHIPPING_ADDRESS = "/shipping_address"
// const EWALLET = "/auth-user/wallet"
const EWALLET = "/fact-wallet"
const ORDER = "/order-item"

// AGENCY URLs
export const getAgencyService = (id, rejectWithValue) =>
  get(`${AGENCY_URL}/${id}`, {}, rejectWithValue)
export const updateAgencyService = agent => put(AGENCY_URL, agent)

// CART URLs
export const getCartService = (data, rejectWithValue) =>
  get(
    `${CART_URL}?customer_id=${data.customer_id}&include[]=products.product_images&include[]=products.merchant_profile
    &include[]=products.whole_sales&include[]=products.variations&include[]=products.variations.variation_options`,
    {},
    rejectWithValue
  )

export const createCartService = cartItem => post(CART_URL, cartItem)

export const updateCartService = (id, data, rejectWithValue, config = {}) =>
  put(`${CART_URL}/${id}`, data, config, rejectWithValue)

export const deleteCartService = (cartId, rejectWithValue) =>
  del(`${CART_URL}/${cartId}`, {}, rejectWithValue)

export const deleteMultipleCartService = (cartIds, rejectWithValue) =>
  del(`${DELETE_MULTIPLE_CART_URL}${cartIds}`, {}, rejectWithValue)

// PRODUCT URLs
export const searchProductService = (query, rejectWithValue) =>
  get(PRODUCT_URL, query, rejectWithValue)

// export const getCurrentProductService = (id, rejectWithValue) =>
//   get(
//     `${CURRENT_PRODUCT_URL}/${id}?include[]=product_images&include[]=reviews&include[]=reviews.agency_profile&include[]=variations.variation_options&include[]=products.merchant_profile`,
//     {},
//     rejectWithValue
//   )

export const getCurrentProductService = (params, rejectWithValue) =>
  get(`${CURRENT_PRODUCT_URL}/${params.id}`, { ...params }, rejectWithValue)

export const getNotificationService = (query, rejectWithValue) =>
  get(NOTIFICATION_URL, query, rejectWithValue)

export const getAgencyNotificationsPageService = (params, rejectWithValue) =>
  get(
    ADMIN_GET_NOTIF_URL_PAGE,
    {
      params: params,
    },
    rejectWithValue
  )

export const getShippingAddressService = (params, rejectWithValue) =>
  get(
    `${SHIPPING_ADDRESS}?include[]=agency_profile`,
    {
      params: params,
    },
    rejectWithValue
  )

// WALLET URLS
export const getWalletBalanceService = (data, rejectWithValue) =>
  get(EWALLET, data, rejectWithValue)

export const receiveItemsService = (data, rejectWithValue, config = {}) =>
  post(
    `${ORDER}/update-agency-order-status?include[]=order_items`,
    data,
    config,
    rejectWithValue
  )
