import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getCategoryService } from "services/category.service"
import { getProductSpecificationsService } from "services/product.service"
import React from "react"
import {
  createProductService,
  deleteProductService,
  getProductsService,
  showProductService,
  updateProductService,
} from "services/merchant.service"
import { createProductReviewService } from "services/product.service"
import { transformData } from "helpers/dataTransformer"
import { defaultMeta } from "config/meta.config"

// Actions
// import { fetchProducts } from "./actions/products"

export const getProducts = createAsyncThunk(
  "/getProducts",
  async (params, { rejectWithValue }) => {
    return await getProductsService(params, rejectWithValue)
  }
)

export const fetchProducts = createAsyncThunk(
  "/fetchProducts",
  async (params, { rejectWithValue }) => {
    return await getProductsService(params, rejectWithValue)
  }
)

export const createProduct = createAsyncThunk(
  "/createProduct",
  async (payload, { rejectWithValue }) => {
    return await createProductService(payload, rejectWithValue)
  }
)

export const showProduct = createAsyncThunk(
  "/showProduct",
  async (params, { rejectWithValue }) => {
    return await showProductService(params, rejectWithValue)
  }
)
export const showMyProduct = createAsyncThunk(
  "/showProduct",
  async (params, { rejectWithValue }) => {
    return await showProductService(params, rejectWithValue)
  }
)
export const updateProduct = createAsyncThunk(
  "/updateProduct",
  async (payload, { rejectWithValue }) => {
    return await updateProductService(payload, rejectWithValue)
  }
)

export const deleteProduct = createAsyncThunk(
  "/deleteProduct",
  async (id, { rejectWithValue }) => {
    return await deleteProductService(id, rejectWithValue)
  }
)

export const getProductSpecifications = createAsyncThunk(
  "/getProductSpecifications",
  async (id, { rejectWithValue }) => {
    return await getProductSpecificationsService(id, rejectWithValue)
  }
)

export const createProductReview = createAsyncThunk(
  "createProductReview",
  async (payload, { rejectWithValue }) => {
    return await createProductReviewService(payload, rejectWithValue)
  }
)

export const uploadProductImage = payload => dispatch => {
  dispatch(slice.actions.uploadProductImage(payload))
}
export const resetShowProduct = () => dispatch => {
  dispatch(slice.actions.resetShowProduct())
}
export const resetProductSpecifications = () => dispatch => {
  dispatch(slice.actions.resetProductSpecifications())
}

const initialState = {
  myProducts: [],
  product: [],
  message: null,
  loading: false,
  product: null,
  success: false,
  error: null,
  type: "",
  actionType: "",
  productIncluded: null,
  specifications: {
    data: [],
    message: null,
    success: false,
    error: null,
  },
  show: {
    data: null,
    message: null,
    success: false,
    error: false,
  },
  fetch: {
    data: [],
    dataTransform: [],
    message: null,
    errorMessage: null,
    success: false,
    error: false,
    largestPrice: 300000,
    meta: defaultMeta,
    type: "",
  },
  create: {
    message: null,
    success: false,
    error: false,
  },
  update: {
    message: null,
    success: false,
    error: null,
  },
  delete: {
    success: false,
    message: null,
    openModal: false,
  },
  review: {
    create: {
      loading: false,
      data: null,
      message: null,
      success: false,
      error: false,
    },
  },
  imageUpload: {
    success: false,
  },
}

const slice = createSlice({
  name: "Product",
  initialState,
  reducers: {
    handleToggleProductActionType(state, action) {
      state.actionType = action.payload
    },
    resetShowProduct: state => {
      state.show = {
        ...state.show,
        data: null,
      }
    },
    resetProductSpecifications: state => {
      state.specifications = {
        ...state.specifications,
        data: [],
      }
    },
    resetProductsState: state => {
      state.type = ""
      state.error = null
      state.success = false
      state.loading = false
      ;(state.message = null),
        (state.review = {
          ...state.review,
          create: {
            ...state.review.create,
            success: false,
            error: false,
          },
        }),
        (state.delete = {
          ...state.delete,
          success: false,
          openModal: false,
        }),
        (state.create = {
          ...state.create,
          success: false,
          error: false,
        }),
        (state.update = {
          ...state.update,
          success: false,
          error: false,
        }),
        (state.fetch = {
          ...state.fetch,
          success: false,
          error: false,
        }),
        (state.imageUpload = {
          success: false,
        }),
        (state.delete = {
          ...state.delete,
          success: false,
          error: false,
        })
    },
    handleToggleDeleteModal(state, action) {
      state.delete.openModal = action.payload
    },
    uploadProductImage: (state, action) => {
      state.imageUpload = {
        success: action.payload,
      }
    },
  },
  extraReducers: {
    [getProducts.pending]: state => {
      ;(state.loading = true),
        (state.fetch = {
          ...state.fetch,
          errorMessage: null,
          type: "getProducts",
        })
    },
    [getProducts.fulfilled]: (state, action) => {
      const products = action.payload.result.data
      const included = action.payload.result.included

      const productsResource = products.reduce((result, sourceItem) => {
        const match = included?.find(targetItem => {
          if (targetItem.type === "product_images") {
            return +targetItem.attributes.product_id === +sourceItem.id
          }
        })

        const matchMerchant = included?.find(targetItem => {
          if (targetItem.type === "merchant_profile") {
            return targetItem.id == sourceItem.attributes.merchant_id
          }
        })

        const matchVariations = included?.filter((item, index) => {
          if (item.type === "product_variations") {
            const found = sourceItem.relationships.variations.data.find(
              source => source.id == item.id
            )
            return found
          }
        })

        const matchWholesales = []
        included?.filter((item, index) => {
          if (item.type === "product_wholesale_pricings") {
            sourceItem.relationships.whole_sales.data.find(source => {
              if (source.id == item.id) {
                matchWholesales.push(item?.attributes)
                return true
              }
            })
          }
        })

        const matchReviews = included?.filter(targetItem => {
          if (targetItem.type === "product_reviews") {
            return targetItem.attributes.product_id == sourceItem.id
          }
        })

        const matchVariationOptions = included?.filter((item, index) => {
          if (item.type === "product_variation_options") {
            const found = sourceItem.relationships.variations.data.find(
              source => source.id == item.attributes.product_variation_id
            )
            return found
          }
        })

        result.push({
          ...sourceItem,
          imageLink: match ? match.attributes.image : "",
          merchant: matchMerchant ? matchMerchant.attributes : null,
          merchant_profile: matchMerchant ? matchMerchant : null,
          variations: matchVariations.length ? matchVariations : null,
          variation_options: matchVariationOptions.length
            ? matchVariationOptions
            : null,
          whole_sales: matchWholesales.length ? matchWholesales : null,
          reviews: matchReviews.length ? matchReviews : null,
        })

        return result
      }, [])

      state.productIncluded = action.payload.result.included

      state.success = true
      state.loading = false

      const largestPrice = action.payload?.other_data?.largest_price
      const meta = action.payload?.result?.meta?.pagination

      state.fetch = {
        ...state.fetch,
        type: "getProducts",
        error: false,
        success: true,
        errorMessage: null,
        data: productsResource,
        largestPrice: largestPrice ? parseInt(largestPrice) : 30000,
        meta,
      }
    },
    [getProducts.rejected]: (state, action) => {
      state.fetch.type = "getProducts"
      state.fetch.error = true
      state.loading = false
      ;(state.fetch.message = action?.payload?.errors ? (
        <>
          <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
          {`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}
        </>
      ) : (
        "Something went wrong"
      )),
        (state.fetch.errorMessage = `${action?.payload?.errors[0]?.code}: ${action?.payload?.errors[0]?.detail}`)
    },
    [showMyProduct.pending]: state => {
      state.loading = true
    },
    [showMyProduct.fulfilled]: (state, action) => {
      state.myProducts = transformData(action?.payload)

      state.success = true
      state.loading = false
    },
    [showMyProduct.rejected]: (state, action) => {
      state.fetch.error = true
      state.loading = false
      state.fetch.message = action?.payload?.errors ? (
        <>
          <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
          {`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}
        </>
      ) : (
        "Something went wrong"
      )
    },
    [updateProduct.pending]: state => {
      state.loading = true
    },
    [updateProduct.fulfilled]: (state, action) => {
      state.update.success = true
      state.update.error = false
      state.loading = false
    },
    [updateProduct.rejected]: (state, action) => {
      state.loading = false
      state.update.error = true
      state.update.success = false
      state.update.message = action.payload?.errors
        ? action.payload?.errors[0]?.detail
        : "Unable to update item, please try again"
    },
    [createProduct.pending]: state => {
      ;(state.loading = true),
        (state.create = {
          ...state.create,
          error: false,
          message: null,
          success: false,
        })
    },
    [createProduct.fulfilled]: (state, action) => {
      state.product = action.payload.result.data
      state.create.success = true
      state.create.error = false
      state.loading = false
    },
    [createProduct.rejected]: (state, action) => {
      state.loading = false
      state.create.error = true
      state.create.success = false
      state.create.message = action.payload?.errors
        ? action.payload?.errors[0]?.detail
        : "Something went wrong, please try again"
    },
    [showProduct.pending]: state => {
      state.loading = true
      state.show = {
        ...state.show,
        data: null,
      }
    },
    [showProduct.fulfilled]: (state, action) => {
      state.product = transformData(action.payload)
      state.show.success = true
      state.show.error = false
      state.loading = false
      state.show = {
        ...state.show,
        data: transformData(action.payload)[0],
      }
    },
    [showProduct.rejected]: (state, action) => {
      state.show.message = action.payload.errors[0].detail
      state.show.success = false
      state.show.error = true
      state.loading = false
    },
    [getProductSpecifications.pending]: state => {
      state.loading = true
    },
    [getProductSpecifications.fulfilled]: (state, action) => {
      const specifications = transformData(action.payload)
      // console.log("product_specs", action.payload);

      state.specifications.data = specifications || []
      state.specifications.success = true
      state.loading = false
    },
    [getProductSpecifications.rejected]: state => {
      state.specifications.error = true
      state.loading = false
    },
    [deleteProduct.pending]: state => {
      state.loading = true
    },
    [deleteProduct.fulfilled]: (state, action) => {
      state.delete.success = true
      state.loading = false
      state.delete.openModal = false
    },
    [deleteProduct.rejected]: (state, action) => {
      state.loading = false
      state.delete.error = true
      state.delete.openModal = false
      state.delete.message = "Something went wrong"
    },
    [createProductReview.pending]: state => {
      state.review.create = {
        ...state.review.create,
        loading: true,
      }
    },
    [createProductReview.fulfilled]: (state, action) => {
      state.review.create = {
        ...state.review.create,
        data: action.payload.result.data,
        loading: false,
        error: false,
        success: true,
      }
    },
    [createProductReview.rejected]: (state, action) => {
      state.review.create = {
        ...state.review.create,
        loading: false,
        error: true,
        message: "Something went wrong",
        success: false,
      }
    },
    [fetchProducts.pending]: state => {
      state.loading = true
      state.fetch = {
        data: [],
        dataTransform: [],
        message: null,
        success: false,
        error: false,
      }
    },
    [fetchProducts.fulfilled]: (state, action) => {
      state.loading = false
      state.fetch = {
        ...state.fetch,
        success: true,
        error: false,
        dataTransform: transformData(action?.payload),
        meta: action.payload.result.meta,
      }
    },
    [fetchProducts.rejected]: (state, action) => {
      state.fetch.error = true
      state.loading = false
      state.fetch.message = action?.payload?.errors ? (
        <>
          <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
          {`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}
        </>
      ) : (
        "Something went wrong"
      )
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { reducer } = slice

export const toggleProductActionType = actionType => dispatch => {
  dispatch(slice.actions.handleToggleProductActionType(actionType))
}

export const toggleDeleteModal = state => dispatch => {
  dispatch(slice.actions.handleToggleDeleteModal(state))
}

export const resetProductsStateThunk = () => dispatch => {
  dispatch(slice.actions.resetProductsState())
}

export default slice
